import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {SystemComponent} from './system.component';
import {BuyComponent} from './buy/buy.component';
import { OpselComponent } from './opsel/opsel.component';
import {FinishComponent} from './finish/finish.component';
import {SellComponent} from './sell/sell.component';
import { ConvertComponent } from './convert/convert.component';
import { GetBtcAddrComponent } from './get-btc-addr/get-btc-addr.component';

const routes: Routes = [
    {path: 'system', component: SystemComponent, children: [
        {path: 'opsel', component: OpselComponent},
        {path: 'buy', component: BuyComponent},
        {path: 'finish', component: FinishComponent},
        {path: 'sell', component: SellComponent},
        {path: 'convert', component: ConvertComponent},
        {path: 'get-btc-addr', component: GetBtcAddrComponent}
        ]}
];

@NgModule ({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class SystemRoutingModule {}
