import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class ImageService {
    constructor(private httpClient: HttpClient) {}

    public getImage(imageUrl: string): Observable<Blob> {
        return this.httpClient
            .get(imageUrl, {
                responseType: 'blob'
            });
    }

    public getCode(url: string): Observable<any> {
        return this.httpClient.get(url, {responseType: 'text'});
    }
}
