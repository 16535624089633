import { TranslateModule } from '@ngx-translate/core';
import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {SystemComponent} from './system.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import { QRCodeModule } from 'angular2-qrcode';
import { SystemRoutingModule } from './system-routing.module';
import { BuyComponent } from './buy/buy.component';
import { ConvertComponent } from './convert/convert.component';
import { FinishComponent } from './finish/finish.component';
import { GetBtcAddrComponent } from './get-btc-addr/get-btc-addr.component';
import { OpselComponent } from './opsel/opsel.component';
import { SellComponent } from './sell/sell.component';

@NgModule({
    declarations: [
        SystemComponent,
        BuyComponent,
        ConvertComponent,
        FinishComponent,
        GetBtcAddrComponent,
        OpselComponent,
        SellComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        SystemRoutingModule,
        TranslateModule,
        QRCodeModule
    ],
    exports: [
      TranslateModule
    ],
    schemas: [ NO_ERRORS_SCHEMA ]
})

export class SystemModule {}
