import { Component, OnInit, OnDestroy } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PayoutService} from '../../shared/services/payout.service';
import {FinServerService} from '../../shared/services/fin.server.service';
import {TranslateService} from '@ngx-translate/core';
import { ConvertState } from './../../shared/services/convert-state';
import {BigNumber} from 'bignumber.js';

@Component({
  selector: 'btcsr-buy',
  templateUrl: './buy.component.pug',
  styleUrls: ['./buy.component.scss']
})
export class BuyComponent implements OnInit, OnDestroy {

  private pollIntervalId = undefined;
  private lastEventId = '';
  public moneyAmount: BigNumber = new BigNumber(0);
  public btcAmount: BigNumber = new BigNumber(0);
  public payOutStarted = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private payoutService: PayoutService,
    public finService: FinServerService,
    private convertState: ConvertState
  ) { }

  ngOnInit() {
      this.setBillsMap();
      this.setBillsRoute();
      this.setBillEnabled();

      this.lastEventId = window.localStorage.getItem('lastEventId');

      this.pollIntervalId = setInterval(() => {
          this.payoutService.getEventLog(this.lastEventId).then(logRes => {
              for (const logRow of logRes) {
                  console.log('Log row: ', logRow);

                  if ((logRow.data !== undefined) && (logRow.data.type !== undefined) && (logRow.data.type === 0)) {
                      const billValue = (logRow.data.value !== undefined) ? logRow.data.value : 0;
                      console.log('Bill: ' + billValue);
                      this.moneyAmount = this.moneyAmount.plus(new BigNumber(billValue));
                      console.log('Money: ', this.moneyAmount.toString());
                      this.btcAmount = (this.moneyAmount.dividedBy(new BigNumber(this.finService.sell_rate))).decimalPlaces(8);
                      console.log('BTC buy: ' + this.btcAmount.toString());
                  }

                  if (logRow.header.uuid !== undefined) {
                      this.lastEventId = logRow.header.uuid;
                      window.localStorage.setItem('lastEventId', this.lastEventId);
                  }
              }

          }).catch(err => {
              console.error('Get log error: ', err);
          });

          this.payoutService.getStatus().then(statusRes => {
            const status = statusRes.status;
            if ((status > 3) && (status < 9)) {
              this.payOutStarted = true;
            } else {
              this.payOutStarted = false;
            }
          }).catch(err => {
              console.error('Get status error: ', err);
          });

      }, 200);
  }

  protected setBillsMap() {
      const billsEnabled = {'bills': [50, 100, 500, 1000]};
      this.payoutService.setBillsMap(billsEnabled).then(setBillsRes => {
          console.log('Set bills res: ', setBillsRes);

      }).catch(err => {
          console.error('Set bills map error: ', err);
      });
  }

  protected setBillsRoute() {
      const billsRoute = {
          'map': [
              {'bill': 50, 'dispensed': true},
              {'bill': 100, 'dispensed': true},
              {'bill': 500, 'dispensed': true},
              {'bill': 1000, 'dispensed': true}
          ]
      };

      this.payoutService.setBillsRoute(billsRoute).then(setRouteRes => {
          console.log('Bills route res: ', setRouteRes);

      }).catch(err => {
          console.error('Set bills route error: ', err);
      });
  }

  protected setBillEnabled() {
      this.payoutService.setAcceptorEnable(true).then(enableRes => {
          console.log('Set enable res: ', enableRes);
      }).catch(err => {
          console.error('Set enable error: ', err);
      });
  }

  ngOnDestroy() {
    this.convertState.moneyAmount = this.moneyAmount;
    this.convertState.btcAmount = this.btcAmount;

    clearInterval(this.pollIntervalId);

    this.payoutService.setAcceptorEnable(false).then(setRes => {
          console.log('Disable res: ', setRes);
      }).catch(err => {
          console.error('Set disable error: ', err);
      });
  }

  makeExit() {
      this.convertState.type = 'cancel';
      this.router.navigate(['/system/finish']);
  }

  makeBuy() {
      this.convertState.type = 'buy';
      this.router.navigate(['/system/get-btc-addr']);
  }

  hasMoney() {
    return this.moneyAmount.gt(new BigNumber(0));
  }

}
