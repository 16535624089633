import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable()
export class SharedDataService {
  accountRub = 0;
  accountBtc = 0;
  currentLang = 'ru';

  @Output() change: EventEmitter<string> = new EventEmitter;

  updateLang(lang: string) {
    console.log('Update lang to ' + lang);
    this.currentLang = lang;
    this.change.emit(this.currentLang);
  }
}
