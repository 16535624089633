import { TranslateService } from '@ngx-translate/core';
import { Component } from '@angular/core';
import { SharedDataService } from './shared/services/shared-data.service';
import { BigNumber } from 'bignumber.js';

@Component({
  selector: 'btcsr-root',
  templateUrl: './app.component.pug',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'BTC seller';

  langSelected = 'ru';

  constructor(private translate: TranslateService,
              private sharedData: SharedDataService) {
                BigNumber.config({ ROUNDING_MODE: 2 });

                this.langSelected = this.translate.getBrowserLang();
                console.log('Lang: ' + this.langSelected);
                this.translate.use(this.langSelected);
                this.sharedData.updateLang(this.langSelected);
              }

  onChange(langVal) {
    console.log('Lang value: ', langVal.value);
    this.langSelected = langVal.value;
    this.translate.use(this.langSelected);
    this.sharedData.updateLang(this.langSelected);
    window.localStorage.setItem('lang', this.langSelected);
  }
}
