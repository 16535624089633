import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'btcsr-get-btc-addr',
  templateUrl: './get-btc-addr.component.html',
  styleUrls: ['./get-btc-addr.component.scss']
})
export class GetBtcAddrComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
