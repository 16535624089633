// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false
};

export const app_settings = {
  webcam_decode_url: 'http://raspberry:8009/getDecodeResult',
  auth_url: 'https://btc.pleasure-time.biz/user/get-user-by-qr-code?qrCode=',
  payout_url: 'http://raspberry:8008/endpoint.json',
  printer_url: 'http://raspberry:8001',
  finServerUrl: 'https://wbgdev.ngmcasino.com/cryptonizer/server/index.php?r=json-rpc-endpoint'
}
