import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'btcsr-lang-sel',
  templateUrl: './lang-sel.component.html',
  styleUrls: ['./lang-sel.component.scss']
})

export class LangSelComponent implements OnInit {

  constructor(
      private translate: TranslateService,
      private router: Router,
      private actRoute: ActivatedRoute
  ) {
      const defaultLang = 'en';
      translate.setDefaultLang(defaultLang);
      window.localStorage.setItem('lang', defaultLang);
  }

  ngOnInit() {
  }

  setLang(lang: string) {
      console.log('Lang: ' + lang);
      this.translate.use(lang);
      window.localStorage.setItem('lang', lang);
      this.router.navigate(['login']);
  }

}
