import { FinServerService } from './../../shared/services/fin.server.service';
import { Component, OnInit } from '@angular/core';
import { PayoutService } from 'src/app/shared/services/payout.service';

@Component({
  selector: 'btcsr-convert',
  templateUrl: './convert.component.pug',
  styleUrls: ['./convert.component.scss']
})
export class ConvertComponent implements OnInit {

  private ifaceLock = false;
  private billsCan;

  constructor(
    private payoutServ: PayoutService,
    private finService: FinServerService
    ) { }

  ngOnInit() {
    // Check bills count in billacceptor
    this.payoutServ.getBillsCount();

    this.waitBillsCount();

  }

  waitBillsCount() {
    if (!this.payoutServ.isBillsLoaded()) {
      window.setTimeout(() => { this.waitBillsCount(); }, 300);
    } else {
      this.billsCan = this.payoutServ.checkKeys(0);
      this.ifaceLock = false;
    }
  }

  showLoader() {
    return this.ifaceLock;
  }

  keyDisabled(key: string | number) {
    if (key === 'exit') {
      return this.ifaceLock;
    }

    return true;
  }

}
