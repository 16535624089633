import { SharedDataService } from './services/shared-data.service';
import { ConvertState } from './services/convert-state';
import { MessageService } from './services/message.service';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from './material.module';
import {ModalMessageComponent} from './components/modal-message/modal-message.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {ImageService} from './services/image.service';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {PayoutService} from './services/payout.service';
import {FinServerService} from './services/fin.server.service';
import {PrintService} from './services/print.service';
import {JsonRpcService} from './services/jsonrpc.service';

@NgModule({
    declarations: [
        ModalMessageComponent,
        NotFoundComponent
    ],
    entryComponents: [
      ModalMessageComponent
    ],
    imports: [
        ReactiveFormsModule,
        FormsModule,
        MaterialModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    exports: [
        ReactiveFormsModule,
        FormsModule,
        MaterialModule,
        ModalMessageComponent,
        TranslateModule
    ],
    providers: [
        ImageService,
        PayoutService,
        FinServerService,
        PrintService,
        JsonRpcService,
        MessageService,
        ConvertState,
        SharedDataService
    ]
})

export class SharedModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
