import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'btcsr-sell',
  templateUrl: './sell.component.html',
  styleUrls: ['./sell.component.scss']
})
export class SellComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
