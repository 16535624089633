import { MatDialog } from '@angular/material';
import { Injectable } from '@angular/core';
import { ModalMessageComponent } from '../components/modal-message/modal-message.component';

@Injectable()
export class MessageService {
  constructor(private dialog: MatDialog) {}
  public show(head: string, message: string, type: string, noClose: boolean = false) {
      this.dialog.open(ModalMessageComponent, {
          width: '350px',
          disableClose: noClose,
          data: {type: type, title: head, message: message, noClose: noClose}
      });
  }

  public close() {
    this.dialog.closeAll();
  }
}
