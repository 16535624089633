import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";

@Component({
  selector: 'bud-modal-message',
  templateUrl: './modal-message.component.html',
  styleUrls: ['./modal-message.component.scss']
})
export class ModalMessageComponent {

  constructor(
      public dialogRef: MatDialogRef<ModalMessageComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any) { }

}
