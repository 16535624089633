import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {app_settings} from '../../../environments/environment';

@Injectable()
export class PrintService {
    constructor(private http: HttpClient) {}

    private handleHttpErrors(error: any) {
        return Promise.reject(error);
    }

    public getStatus() {
        return this.http.get(app_settings.printer_url + '/get_info.jsonp')
            .toPromise()
            .catch(this.handleHttpErrors);
    }

    public printText(text: string) {
        return this.http.get(app_settings.printer_url + '/send_printer_task?task=' + text)
            .toPromise()
            .catch(this.handleHttpErrors);
    }

    public printBarcode(code: string) {
        return this.http.get(app_settings.printer_url + '/send_printer_qrcode?code=' + code)
            .toPromise()
            .catch(this.handleHttpErrors);
    }
}
