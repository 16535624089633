import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {app_settings} from '../../../environments/environment';
import * as moment from 'moment';
import {BigNumber} from 'bignumber.js';

@Injectable()
export class FinServerService {
    offer_id: number = undefined;
    buy_rate: BigNumber = undefined;
    sell_rate: BigNumber = undefined;
    offer_expired: string = undefined;

    private uniq_id_counter = 0;
    private term_serial = '12345';
    private client_version = '0.2.1';

    private finServerUrl = app_settings.finServerUrl;
    private headers: HttpHeaders;

    constructor (private http: HttpClient) {
      this.headers = new HttpHeaders();
      this.headers.append('Content-Type', 'application/json');
    }

    private handleHttpErrors(error: any) {
      return Promise.reject(error.message || error);
    }

    private handleServErrors(response: any) {
        if (!response.error) {
          return response;
        }

        const error  = response.error;
        const errorObj = {
          title: 'Server error!',
          message: error.message,
          fullTrace: error
        };

        console.log('Error:', error);

        return Promise.reject(errorObj);
    }


    private buildRequest(method: string, params: any) {

      // const tzoffset = (new Date()).getTimezoneOffset() * 60000; // offset in milliseconds
      // const localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);

      this.headers.append('version', this.client_version);
      this.headers.append('datetime', moment(Date.now()).format('YYYY-MM-DDTHH:mm:ssZ'));
      this.headers.append('sn', this.term_serial);
      this.headers.append('emulation', '1');

      this.uniq_id_counter++;

      return JSON.stringify({
          jsonrpc: '2.0',
          id: this.uniq_id_counter,
          method: method,
          params: params
      });
    }

    public getOffer() {
      const body = this.buildRequest('offer-actual-get', {});

      this.http.post(this.finServerUrl, body, {headers: this.headers})
              .toPromise()
              .then(this.handleServErrors)
              .then(res => {
                this.offer_id = res.id;
                this.buy_rate = new BigNumber(res.buy_rate).dividedBy(new BigNumber(100)).decimalPlaces(2);
                this.sell_rate = new BigNumber(res.sell_rate).dividedBy(new BigNumber(100)).decimalPlaces(2);
                this.offer_expired = res.expiry_unix_time;
              })
              .catch(this.handleHttpErrors);
    }



    public getBuyRate() {
      return this.buy_rate;
    }

    public getSellRate() {
      return this.sell_rate;
    }

    public getOfferId() {
      return this.offer_id;
    }

    // Sell crypto to user
    public sellCrypto(walletId: string, btcAmount: number, rubAmount: number, offer_id: number): Promise<any> {
        const params = {
          client_address: walletId,
          amount: btcAmount,
          cost: rubAmount,
          offer_id: offer_id
        };

        const body = this.buildRequest('sell', params);

        return this.http.post(this.finServerUrl, body, {headers: this.headers})
            .toPromise()
            .then(this.handleServErrors)
            .catch(this.handleHttpErrors);
    }

    // Buy crypto from user
    public buyCrypto(btcAmount: number, rubAmount: number, offer_id: number): Promise<any> {
      const params = {
        amount: btcAmount,
        cost: rubAmount,
        offer_id: offer_id
      };

      const body = this.buildRequest('buy', params);

      return this.http.post(this.finServerUrl, body, {headers: this.headers})
          .toPromise()
          .then(this.handleServErrors)
          .catch(this.handleHttpErrors);
    }

    // Get transaction status
    public getTrxStatus(trx_id: number) {
      const params = {
        trx_id: trx_id
      };

      const body = this.buildRequest('get-trx-status', params);

      return this.http.post(this.finServerUrl, body, {headers: this.headers})
          .toPromise()
          .then(this.handleServErrors)
          .catch(this.handleHttpErrors);
    }

}
