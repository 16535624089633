import { FinServerService } from './../shared/services/fin.server.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'btcsr-system',
  templateUrl: './system.component.pug',
  styleUrls: ['./system.component.scss']
})
export class SystemComponent implements OnInit {

  private finPollId = undefined;

  constructor(private finService: FinServerService) { }

  ngOnInit() {
    this.finPollId = window.setInterval(() => {
        this.finService.getOffer();
      }, 5000);
  }

}
