import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {JsonRpcService} from './jsonrpc.service';
import {app_settings} from '../../../environments/environment';
import { promise } from 'protractor';


@Injectable()
export class PayoutService {

    private amount = 0;
    private billNominals = [5000, 1000, 2000, 500, 200, 100, 50];
    private billAmount = [0, 0, 0, 0, 0, 0, 0];
    private billsCan = [0, 0, 0, 0, 0, 0, 0];
    private billsLoaded = false;

    constructor(private rpcService: JsonRpcService) {}

    public getStatus() {
        return this.rpcService.sendRequest(app_settings.payout_url, 'StatusGet', {});
    }

    public setBillsMap(billsMap: Object) {
        return this.rpcService.sendRequest(app_settings.payout_url, 'AcceptorBillsEnabledMapSet', billsMap);
    }

    public setBillsRoute(billsRoute: Object) {
        return this.rpcService.sendRequest(app_settings.payout_url, 'AcceptorBillsRouteSet', billsRoute);
    }

    public setAcceptorEnable(enable: boolean) {
        const params = {'value': enable};
        return this.rpcService.sendRequest(app_settings.payout_url, 'AcceptorEnableSet', params);
    }

    public getEventLog(lastUid: string) {
        const params = {'uuid': lastUid};
        return this.rpcService.sendRequest(app_settings.payout_url, 'EventLogGet', params);
    }

    public makePayOut(value: number) {
        const params = {'value': value};
        return this.rpcService.sendRequest(app_settings.payout_url, 'DispenserPayOutExec', params);
    }

    public getBillsCount() {
      this.billsLoaded = false;
      this.rpcService.sendRequest(app_settings.payout_url, 'DispenserBillsCountMapGet', {})
        .then(res => { this.setBills(res); } );
    }

    private setBills(response: any) {
        for (const billRow of response) {
          const billVal = billRow.bill;
          const billCount = billRow.count;
          const billIndex = this.billNominals.indexOf(billVal);
          if (billIndex > -1 ) {
            this.billAmount[billIndex] = billCount;
          }
        }
        this.billsLoaded = true;
        console.log('Bills count: ', this.billAmount);
    }

    public isBillsLoaded() {
      return this.billsLoaded;
    }

    protected getBills(amount: number) {

      const billCount = [0, 0, 0, 0, 0, 0, 0];
      const bills = this.billAmount.slice();
      let rest = amount;

      let i = 0;

      while ((rest > 0) && (i < this.billNominals.length)) {
        if ((bills[i] === 0) || (this.billNominals[i] > rest)) {
          i++;
        } else {
          rest = rest - this.billNominals[i];
          bills[i]--;
          billCount[i]++;
        }
      }

      return ( rest === 0 );
    }

    public checkKeys(amount: number) {
      for (let i = 0; i < this.billNominals.length; i++) {
        if (this.getBills(amount + this.billNominals[i])) {
          this.billsCan[i] = 1;
        } else {
          this.billsCan[i] = 0;
        }
      }

      return this.billsCan;
    }

}
