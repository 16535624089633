import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class JsonRpcService {
    private uniq_id_counter: number = 0;
    private headers: HttpHeaders;

    constructor(private http: HttpClient) {}

    private buildRequest(method: string, params: any) {
        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/json');


        return JSON.stringify({
           jsonrpc: '2.0',
           id: this.uniq_id_counter,
           method: method,
           params: params
        });
    }

    private handleHttpErrors(error: any) {
        return Promise.reject(error.message || error);
    }

    private handleServErrors(response: any) {
        if (!response.error) {
           return response.result;
        }

        const error  = response.error;
        const errorObj = {
          title: 'Server error!',
          message: error.message,
          fullTrace: error
        };

        console.log('Error:', error);

        return Promise.reject(errorObj);
    }

    public sendRequest(url: string, method: string, params: Object): Promise<any> {
        const body = this.buildRequest(method, params);

        return this.http.post(url, body, {headers: this.headers})
            .toPromise()
            .then(this.handleServErrors)
            .catch(this.handleHttpErrors);
    }


}
