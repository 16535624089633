import { SharedDataService } from './../../shared/services/shared-data.service';
import { ConvertState } from './../../shared/services/convert-state';
import { MessageService } from './../../shared/services/message.service';
import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'btcsr-opsel',
  templateUrl: './opsel.component.pug',
  styleUrls: ['./opsel.component.scss']
})

export class OpselComponent implements OnInit {

    private exitIntervalId = undefined;
    public secondsToExit = 45;

    constructor(private router: Router, private route: ActivatedRoute, private translation: TranslateService,
      private message: MessageService, private convertState: ConvertState, public sharedData: SharedDataService) { }

    ngOnInit() {
      this.convertState.clear();
    }

    makeBuy() {
      this.convertState.type = 'buy';
  //    this.router.navigate(['/system/buy']);
    }

    makeSell() {
      this.convertState.type = 'sell';
 //     this.router.navigate(['/system/convert']);
    }

    makeExit() {
      this.router.navigate(['/']);
    }
}
