import { Injectable } from '@angular/core';
import {BigNumber} from 'bignumber.js';

@Injectable()
export class ConvertState {
  type: string = undefined;
  btcAmount: BigNumber = new BigNumber(0);
  moneyAmount: BigNumber = new BigNumber(0);
  walletAddr = undefined;

  clear() {
    this.type = undefined;
    this.btcAmount = new BigNumber(0);
    this.moneyAmount = new BigNumber(0);
    this.walletAddr = undefined;
  }

}
